.container{
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  gap: 0.5em;
}
.title{
  font-weight: bold;
  font-size: 1.10em;
}
.item {
  display: grid;
  justify-content: left;
  align-items: center;
  font-size: 1em;
  padding: 0.5em;
}
input.item,
select.item {
  min-width: 0;
}

span.item{
  display: flex;
}
.anchor{
  cursor: pointer;
  color: blue;
}
div.item{
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}
.container:hover{
  background-color: rgba(200, 200, 200, 0.250);
}
.title:hover,
.filter:hover{
  background-color: transparent;
}