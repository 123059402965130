h1,
h2 {
  font-weight: normal;
}

button {
  font-family: "Roboto", sans-serif;
  background-color: white;
  padding: 0.5em 1.2em;
  border: 0.1em solid black;
  margin: 10px;
  border-radius: 5px;
}
button:hover {
  cursor: pointer;
}
.title {
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-decoration: none;
  color: black;
}
.link{
  margin-right: 2em;
  text-decoration: none;
}
.error{
  border: 0.1em solid red;
}