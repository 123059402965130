.container{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  gap: 0.5em;
}
.title{
  font-weight: bold;
  font-size: 1.10em;
}
.item {
  display: grid;
  justify-content: left;
  align-items: center;
  font-size: 1em;
  padding: 0.5em;
}
input.item,
select.item {
  min-width: 0;
}
.btn-collect{
  margin-left: 0;
  display: flex;
  align-items: center;
  color: #62b855;
  border: 0.1em solid #62b855;
}
.svg-collect{
  width: 1.75em;
  filter: invert(66%) sepia(61%) saturate(391%) hue-rotate(65deg) brightness(86%) contrast(90%);
}
.infinity-load{
  width: 1.75em;
}
.container:hover{
  background-color: rgba(200, 200, 200, 0.250);
}
.title:hover,
.filter:hover{
  background-color: transparent;
}
.btn-collect:disabled{
  cursor: not-allowed;
  color:lightslategrey;
}